import Header from "../../components/Header/Header";
import HeaderImageCarousel from "../../components/HeaderImageCarousel";
import Footer from "../../components/Footer/Footer";
import React from "react";
import hospitalImage from '../../assets/InfoPane_images/Hospital_resized_wider.png';
import DoctorImage from '../../assets/InfoPane_images/Doctor Image and Name.png';

const About = () => {
    return (
        <>
            <Header />
            <HeaderImageCarousel />
            <Content />
            <Footer />
        </>
    );
}

const Content = () => {
    return (
        <div className="flex justify-center my-10 px-4 lg:px-10">
            <div className="max-w-[1200px] w-full">
                <FirstBlock />
                <div className="my-20 w-full" />
                <SecondBlock />
                <div className="my-20 w-full" />
                <BookAppointmentBlock />
            </div>
        </div>
    );
}

const FirstBlock = () => {
    return (
        // <div className="flex flex-col md:flex-row gap-5 mb-10 px-4 lg:px-8">
        //     <div className="flex-1">
        //         {/* Content */}
        //         <div className="my-5">
        //             <h2 className="font-bold text-2xl mb-3 text-reach-blue">Welcome to Reach Hospital</h2>
        //             <p className="text-justify text-sm md:text-lg">
        //             At Reach Super Speciality Hospital, your health and well-being are our top priorities. We are committed to providing exceptional healthcare services, guided by a patient-first approach and supported by our team of experienced and compassionate medical professionals. Whether you have questions about our services, need assistance with scheduling an appointment, or seek information on treatment options, our dedicated team is here to support you every step of the way.
        //             </p>
        //         </div>
        //         <div className="my-5">
        //             <h2 className="font-bold text-2xl mb-3 text-reach-blue">Why Choose Us</h2>
        //             <p className="text-justify text-sm md:text-lg">
        //             Reach Hospital stands out with a patient-centered approach focused on personalized care and healing lives. Equipped with state-of-the-art technology and staffed by highly trained professionals, we are dedicated to advanced treatments that support both recovery and well-being.
        //             </p>
        //         </div>
        //     </div>
        //     <div className="flex-1 flex justify-center">
        //         {/* Image */}
        //         <div className="w-full md:ml-10">
        //             <img
        //                 src={hospitalImage}
        //                 alt="Hospital"
        //                 className=" w-full object-cover rounded-lg shadow-lg"
        //             />
        //         </div>
        //     </div>
        // </div>
        <div className="flex flex-col md:flex-row items-center">
            {/* Text Section */}
            <div className="flex-1 px-4 md:px-8">
                <h2 className="text-2xl font-bold mb-4">Welcome to Reach Hospital</h2>
                <p className="text-justify text-sm md:text-lg mb-4">
                At Reach Super Speciality Hospital, your health and well-being are our top priorities. We are committed to providing exceptional healthcare services, guided by a patient-first approach and supported by our team of experienced and compassionate medical professionals. Whether you have questions about our services, need assistance with scheduling an appointment, or seek information on treatment options, our dedicated team is here to support you every step of the way.
                </p>
                <h3 className="text-2xl font-bold mb-2">Why Choose Us</h3>
                <p className="text-justify text-sm md:text-lg">
                Reach Hospital stands out with a patient-centered approach focused on personalized care and healing lives. Equipped with state-of-the-art technology and staffed by highly trained professionals, we are dedicated to advanced treatments that support both recovery and well-being.
                </p>
            </div>
            {/* Image Section */}
            <div className="flex-1 px-4 md:px-8">
                <img
                    src={hospitalImage}
                    alt="Hospital"
                    className="w-full h-full object-contain rounded-lg shadow-lg"
                />
            </div>
        </div>

    );
};

const SecondBlock = () => {
    return (
        <div className="flex flex-col md:flex-row gap-5 mb-10 px-4 lg:px-8">
            <div className="flex-1 flex justify-center">
                {/* Image */}
                <div className="w-full md:h-[500px]">
                    <img
                        src={DoctorImage}
                        alt="Hospital"
                        className="h-full w-full object-cover rounded-lg shadow-lg"
                    />
                </div>
            </div>
            <div className="flex-1 md:ml-10">
                {/* Content */}
                <div className="my-5">
                    <h2 className="font-bold text-2xl mb-3 text-reach-blue">Our Values</h2>
                    <p className="text-justify text-sm md:text-lg text-left">
                    At Reach Hospital, our core values—Integrity, Compassion, and Innovation—guide our commitment to exceptional healthcare. We maintain Integrity through transparency and ethical standards, fostering trust with our patients. Compassion ensures that each individual receives respectful, empathetic care in a supportive environment. Through Innovation, we utilize the latest medical advancements to provide tailored, cutting-edge treatments. Together, these values drive our pursuit of excellence and continuous improvement.                    </p>
                </div>
                <div className="my-5">
                    <h2 className="font-bold text-2xl mb-3 text-reach-blue">Looking Forward</h2>
                    <p className="text-justify text-sm md:text-lg text-left">
                        As we move forward, Reach Hospital remains dedicated to innovation and excellence in healthcare. We continue to expand our services and embrace new technologies.
                    </p>
                </div>
            </div>
        </div>
    );
}

const BookAppointmentBlock = () => {
    return (
        <div className="flex flex-col md:flex-row gap-5 bg-gray-100 rounded-lg  mb-10 mx-auto max-w-[1200px]">
            <div className="flex-1 m-10">
                {/* Content */}
                <p className="text-justify text-sm md:text-lg mb-10">
                    Your health is our priority. Book your appointment today and trust our experienced team to provide the compassionate, expert care you deserve.
                </p>
                <button className="px-4 py-3 bg-reach-green rounded-md text-white font-bold cursor-pointer hover:bg-green-700 transition-all duration-200">
                    Book an Appointment
                </button>
            </div>
            <div className="flex-1 flex justify-center">
                {/* Image */}
                <div className="w-full md:h-[350px] ">
                    <img
                        src={hospitalImage}
                        alt="Hospital"
                        className="h-full w-full object-fit: cover rounded-lg shadow-lg"
                    />
                </div>
            </div>
        </div>
    );
}

export default About;
