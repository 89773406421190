import React, { useState, useEffect } from "react";
import DoctorCard from "../DoctorCard";
import Srujana from "../../assets/DoctorImages/SrujanaReddy.jpg";
import RamKrishna from "../../assets/DoctorImages/RamaKrishnaReddy.jpg";
import Raghuveer from "../../assets/DoctorImages/Raghuveer.jpg";
import Ujwal from "../../assets/DoctorImages/Ujwal.jpg";
import ChandraKiran from "../../assets/DoctorImages/Chandra Kiran.jpg";
import Navaneetha from "../../assets/DoctorImages/Dr. G. Navneetha.jpg";
import SusruthKumar from "..//..//assets/DoctorImages/Dr. B. Susruth Kumar.jpg";
import AkshayKumar from "../../assets/DoctorImages/Akshay Kumar.jpg"
import Swapnil from "../../assets/DoctorImages/Dr. Swapnil Kolpakwar.jpg";
import Shruthi from "../../assets/DoctorImages/Shruthi.jpg";
import DVC from "../../assets/DoctorImages/DVC.jpg";
import Bagam from "../../assets/DoctorImages/BAGAM MAHESH YADAV.jpg";
import uma from "..//..//assets/DoctorImages/Uma Devi.jpg"
const DoctorsPane = () => {
  const [showAll, setShowAll] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const doctors = [
    {
      name: " Dr. Ramakrishna Reddy Pakala",
      img: RamKrishna,
      title: "Managing Director",
      qualifications: " MBBS, MS, FISCP, Consultant General, Laparoscopic and Laser Surgeon",
      languages: "Telugu, English, Hindi",
      timings: "Mon-Fri ( 9AM - 12PM, 2PM - 7PM)"
    },
    {
      name: "Dr. G. Navneetha",
      img: Navaneetha,
      title: "Medical Director",
      qualifications: "MBBS, DNB MEDICINE Consultant General Physician & Diabetologist",
      languages: "Telugu, English, Hindi",
      timings: "Mon-Fri ( 9AM - 12PM, 2PM - 7PM)"
    },
    {
      name: "Dr. Srujana Reddy Kasarla",
      img: Srujana,
      title: "Consultant Paediatrician",
      qualifications: "MBBS,MD PEDIATRICS NUTRITION SPECIALIST", 
      languages: "Telugu, English, Hindi",
      timings: "Mon-Fri ( 9AM - 12PM, 2PM - 7PM)"
    },
    {
        name: "Dr. Chunchu Chandra Kiran",
        img: ChandraKiran,
        title: "Consultant Paediatrician and Neonatologist",
        qualifications: "MBBS,DNB. Paediatrics  Fellowship in Neonatology(NNF)",
        languages: "Telugu, English, Hindi",
        timings: "Mon-Fri ( 9AM - 12PM, 2PM - 7PM)"
    },
    {
      name: "Dr. B. Susruth Kumar",
      img: SusruthKumar,
      title: "Consultant Interventional Cardiologist",
      qualifications: " MD, DM Cardiology (Osmania - Gold Medalist)",
      languages: "Telugu, English, Hindi",
      timings: "Mon-Fri ( 9AM - 12PM, 2PM - 7PM)"
    },
    {
      name: "Dr. Akshay Kumar Duvva",
      img: AkshayKumar,
      title: "MBBS,MD Pulmonology",
      qualifications: "Consultant Interventional Pulmonologist and Critical Care",
      languages: "Telugu, English, Hindi",
      timings: "Mon-Fri ( 9AM - 12PM, 2PM - 7PM)"
    },
    {
      name: " Dr. Swapnil Kolpakwar",
      img: Swapnil,
      title: "Consultant Neurosurgeon & Spine Surgeon",
      qualifications: "MBBS, MS (Surgery), MCh (Neurosurgery), Fellowship in Pain Medicine (FIPM)",
      languages: "Telugu, English, Hindi",
      timings: "Mon-Fri ( 9AM - 12PM, 2PM - 7PM)"
    },
    {
      name: " Dr. Ujwal Gajula",
      img: Ujwal,
      title: "Consultant Gastroenterologist & Hepatologist",
      qualifications: " MBBS, DNB Medicine (Pune), DrNB Gastroenterology",
      languages: "Telugu, English, Hindi",
      timings: "Mon-Fri ( 9AM - 12PM, 2PM - 7PM)"
    },
    {
      name: "Dr. Raghuveer Karne",
      img: Raghuveer,
      title: "Consultant Urologist& Andrologist",
      qualifications: "MBBS, MS (General Surgery), DNB (Genitourinary Surgery)",
      languages: "Telugu, English, Hindi",
      timings: "Mon-Fri ( 9AM - 12PM, 2PM - 7PM)"
    },
    {
      name: "Dr. R. Shruthi Reddy",
      img: Shruthi,
      title: "Consultant Obstetrician & Gynaecologist",
      qualifications: "MBBS,MS OBSTETRICS AND GYNAECOLOGY, LAPAROSCOPIC SURGEON, INFERTILITY SPECIALIST ",
      languages: "Telugu, English, Hindi",
      timings: "Mon-Fri ( 9AM - 12PM, 2PM - 7PM)"
    },
    {
      name: "Dr.D.Vijaya Chandrika Ravali",
      img: DVC,
      title: "Consultant Nutritionist",
      qualifications: "M.B.B.S; International Certified Nutritionist ",
      languages: "Telugu, English, Hindi",
      timings: "Mon-Fri ( 9AM - 12PM, 2PM - 7PM)"
    },
    {
      name: "Dr V Uma devi",
      img: uma,
      title: "CONSULTANT OBSTETRICIAN AND GYNAECOLOGIST",
      qualifications: "MBBS MS OBG ",
      languages: "Telugu, English, Hindi",
      timings: "Mon-Fri ( 9AM - 12PM, 2PM - 7PM)"
    },
    {
      name: "Dr.BAGAM MAHESH YADAV",
      img: Bagam,
      title: "Consultant orthopaedician",
      qualifications: "MBBS D.Ortho",
      languages: "Telugu, English, Hindi",
      timings: "Mon-Fri ( 9AM - 12PM, 2PM - 7PM)"
    },
  ];

  const maxCardsDesktop = 4;
  const maxCardsMobile = 2;

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);  // Mobile: <= 768px
      } else {
        setIsMobile(false); // Desktop: > 768px
      }
    };

    // Set initial state
    handleResize();

    // Add event listener to handle resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleViewAll = () => {
    setShowAll(!showAll); 
  };

  return (
    <div className="w-full p-4 lg:py-10 lg:px-48">
      <div className="flex justify-between mb-5">
        <div className="text-xl md:text-2xl font-bold text-reach-blue">Our Doctors</div>
        <div className="text-lg md:text-xl text-reach-orange font-bold cursor-pointer" onClick={handleViewAll}>
          {showAll ? "Show less" : "View all"}
        </div>
      </div>

      <div className="w-full justify-center flex flex-wrap gap-5 lg:gap-14">
        {doctors.slice(0, showAll ? doctors.length : (isMobile ? maxCardsMobile : maxCardsDesktop)).map((doctor, index) => (
          <div key={index}>
            <DoctorCard doctor={doctor} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default DoctorsPane;